import React from 'react';
import { Map, MapRetailerCard } from 'src/features/dealer-picker';
import styles from './MapView.module.css';

export const MapView = (): JSX.Element => {
  return (
    <div
      className={`${styles.wrapper} sticky flex flex-shrink w-full lg:ml-64 lg:max-w-1/2`}
      style={{
        '--height': 'calc(100vh - var(--sitenav-topbar-height, 48px))',
        '--top': 'calc(var(--sitenav-topbar-height, 48px) - 1px)',
      }}
    >
      <Map />
      <MapRetailerCard />
    </div>
  );
};
