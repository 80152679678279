import React, { useCallback } from 'react';
import Link from 'next/link';
import { Icon } from '@volvo-cars/react-icons';
import { useTracker } from '@volvo-cars/tracking';
import { Lead } from '@volvo-cars/tracking/dist/types';
import { RetailerLink, useStore, useTranslate } from 'src/hooks';
import { TRACKING_CATEGORY } from '../../../constants/tracking';
import styles from './RetailerLinksGroup.module.css';

export const RetailerLinksGroup = ({
  dealerId,
  dealerInfo,
  links,
}: Lead & { links: RetailerLink[] }) => {
  const { view } = useStore();
  const translate = useTranslate();
  const track = useTracker();

  const sendGtmClickEvent = useCallback(
    (eventLabel: string) => {
      track.customEvent({
        dealerId,
        dealerInfo,
        eventAction: 'link|click',
        eventCategory: TRACKING_CATEGORY,
        eventLabel,
      });
    },
    [dealerId, dealerInfo, track],
  );
  return (
    <div
      className={`${view === 'map' ? 'grid md:grid-cols-2' : 'flex-col'} gap-x-24 mt-16 w-full`}
    >
      {links.map(({ url, key, eventLabel, defaultLabel }) => (
        <Link
          href={url}
          key={url}
          onClick={() => sendGtmClickEvent(eventLabel)}
          target="_blank"
          className={`${view === 'map' ? styles.mapBorder : styles.border} py-16`}
        >
          <div className="flex items-center justify-between">
            <p className="font-medium text-accent-blue hover:text-primary">
              {translate(key) || defaultLabel}
            </p>
            <Icon color="accent-blue" type="navigation-chevronforward-12" />
          </div>
        </Link>
      ))}
    </div>
  );
};
