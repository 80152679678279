import React, { memo, useEffect } from 'react';
import { SearchInputContextValue } from 'src/providers';
import { parsePrediction } from 'src/utils/parsePrediction';
import { AutocompleteResponseData } from 'src/api-helpers/google-maps-api';
import { useSelectorSettings } from 'src/hooks';
import { UseMyLocation } from './UseMyLocation';
import styles from './SuggestionsBox.module.css';

type SuggestionsBoxProps = {
  handlePredictionSelect: any;
  predictions?: AutocompleteResponseData['data'] | null;
  suggestionsVisible: boolean;
  highlightIndex: number;
  setInput: SearchInputContextValue['setInput'];
};
const SuggestionsBox = ({
  handlePredictionSelect,
  predictions,
  suggestionsVisible,
  highlightIndex,
  setInput,
}: SuggestionsBoxProps): JSX.Element => {
  const predictionLength = predictions?.length;
  const [isMyLocationLoading, setIsMyLocationLoading] = React.useState(false);
  const { useSelector } = useSelectorSettings();
  const handleIsMyLocationLoading = (isLoading: boolean) => {
    setIsMyLocationLoading(isLoading);
  };

  useEffect(() => {
    if (!predictionLength || !predictions) return;
    if (highlightIndex >= 0 && highlightIndex <= predictionLength - 1) {
      setInput({
        value: predictions[highlightIndex].description,
        skipFetch: true,
        resetPredictions: false,
      });
    }
  }, [highlightIndex, predictionLength, predictions, setInput]);
  return (
    <>
      {suggestionsVisible && (
        <ul
          className={`${styles.wrapper} absolute w-full px-24 bg-primary z-overlay`}
          style={{ '--top': useSelector ? '5rem' : '8rem' }}
        >
          <UseMyLocation setIsLoading={handleIsMyLocationLoading} />
          {isMyLocationLoading && (
            <progress aria-label="Loading" className="spinner m-auto flex" />
          )}
          {predictions?.map((prediction, i: number) => {
            return (
              <li key={i}>
                <button
                  className={`${styles.listItemWrapper} w-full text-start overflow-hidden whitespace-nowrap rounded py-8 dl-text-ellipsis`}
                  onClick={() => handlePredictionSelect(prediction)}
                  onMouseDown={(event: any) => event.preventDefault()}
                  data-testid="dealer:suggestions"
                  type="button"
                >
                  {parsePrediction(prediction).map((substr, key) => (
                    <p
                      key={key}
                      className={`${substr.type === 'bold' && 'font-medium'} whitespace-nowrap inline overflow-hidden`}
                    >
                      {substr.text}
                    </p>
                  ))}{' '}
                  <p className="text-secondary inline whitespace-nowrap inline">
                    {prediction.structured_formatting.secondary_text}
                  </p>
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default memo(SuggestionsBox);
