import React, { useCallback, useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import { Block, ExtendCSS } from 'vcc-ui';
import { useTracker } from '@volvo-cars/tracking';
import { useStore } from 'src/hooks';
import { StoreContextValue } from 'src/providers';
import { updateMapPanBoundsZoom } from 'src/utils/mapUtils';
import { TRACKING_CATEGORY } from '../../../constants/tracking';
import { getDealerId } from '../../../utils/volvoTrackingHelpers';

export const Marker = ({
  visible,
  lat,
  lng,
  map,
  retailer,
}: {
  visible: boolean;
  lat: number;
  lng: number;
  map?: google.maps.Map | null;
  retailer: StoreContextValue['selectedRetailer'];
}) => {
  const [show, setShow] = useState(false);
  const { dispatch } = useStore();
  const track = useTracker();
  const ref = useRef<any>();
  const transitions = useTransition(show, {
    config: springConfig,
    from: { scale: 0 },
    enter: [{ scale: 1 }],
    leave: [{ scale: 0 }],
    unique: true,
  });
  useEffect(() => {
    setShow(false);
    const timeout =
      visible &&
      setTimeout(() => {
        setShow(true);
      }, 200);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [lat, lng, visible]);

  const handleClick = useCallback(() => {
    if (!map) return;
    dispatch({ type: 'SET_RETAILERSLIST_VISIBLE', payload: true });

    // postpone zoom until current queue is done to prevent race condition with map extend/fitbounds
    updateMapPanBoundsZoom(map, { lat, lng, zoom: 17 }, 50);

    retailer &&
      dispatch({ type: 'SET_SELECTED_RETAILER', payload: { ...retailer } });
    track.customEvent({
      eventAction: 'card|select',
      eventCategory: TRACKING_CATEGORY,
      eventLabel: 'retailer marker',
      dealerId: getDealerId(retailer),
      dealerInfo: retailer?.name ?? undefined,
    });
  }, [map, dispatch, retailer, track, lat, lng]);

  const element = ref.current;
  // prevent bubbling click event to other map elements
  useEffect(() => {
    if (!map) return;
    element && google.maps.OverlayView.preventMapHitsFrom(element);
  }, [element, map]);
  return (
    <button
      type="button"
      aria-label={`${retailer?.name} map marker`}
      onClick={handleClick}
    >
      {transitions((props, item, _, index) => {
        return (
          <React.Fragment key={index}>
            {show && item && (
              <Block
                as={animated.div}
                ref={ref}
                style={{
                  // eslint-disable-next-line react/prop-types
                  transform: props.scale.to((s) => `scale(${s})`) as any,
                }}
                extend={wrapperCSS}
                data-testid="dealer:mapLocationPin"
              />
            )}
          </React.Fragment>
        );
      })}
    </button>
  );
};
const wrapperCSS: ExtendCSS = ({ theme: { color } }) => ({
  height: 24,
  width: 24,
  zIndex: 1000,
  borderRadius: '100%',
  background: color.brand.primary,
  cursor: 'pointer',
});
const springConfig = {
  friction: 14,
  tension: 250,
};
