import React, { useEffect } from 'react';
import { useStore } from 'src/hooks/useStore';
import { SearchInputProvider } from 'src/providers';
import { BOTTOM_BAR_HEIGHT } from 'src/constants/sizes';
import styles from './ListView.module.css';
import { AutocompleteAddress } from './search/AutocompleteAddress';
import { HelpText } from './search/HelpText';
import { RetailersList } from './RetailersList';

const ListView = ({ initialSearchValue }: { initialSearchValue?: string }) => {
  const { suggestionsVisible, view, retailersListVisible } = useStore();
  const [isMyLocationLoading, setIsMyLocationLoading] = React.useState(false);

  const handleIsMyLocationLoading = (isLoading: boolean) => {
    setIsMyLocationLoading(isLoading);
  };

  useEffect(() => {
    //Not ideal should be removable once larger bug of initial scroll down fixed
    const handleScroll = () => {
      if (view === 'map' || suggestionsVisible) {
        window.scrollTo({ top: 0, behavior: 'instant' });
      }
    };

    window.addEventListener('scroll', handleScroll, false);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, [view, suggestionsVisible]);

  return (
    <div
      className={`${styles.listWrapper} w-full lg:mr-64 flex-shrink-0`}
      style={{
        '--min-height':
          view === 'list'
            ? `calc(100vh - calc(var(--sitenav-topbar-height, 48px) + ${BOTTOM_BAR_HEIGHT}px))`
            : 'auto',
      }}
    >
      <SearchInputProvider initialSearchValue={initialSearchValue}>
        <AutocompleteAddress />
        <div
          className={`${suggestionsVisible && 'hidden'} w-full flex justify-center flex-col px-24 md:mx-auto`}
        >
          {isMyLocationLoading && (
            <progress
              aria-label="Loading"
              className="spinner m-auto flex my-16"
            />
          )}
          {!retailersListVisible && (
            <HelpText setIsLoading={handleIsMyLocationLoading} />
          )}
          {view === 'list' && <RetailersList />}
        </div>
      </SearchInputProvider>
    </div>
  );
};

export default ListView;
