import React, { useState } from 'react';
import { Icon } from '@volvo-cars/react-icons';
import { Track, TrackingProvider } from '@volvo-cars/tracking';
import { useStore, useTranslate } from 'src/hooks';
import { TRACKING_CATEGORY } from '../../constants/tracking';

export const Bottombar = () => {
  const { dispatch } = useStore();
  const translate = useTranslate();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <TrackingProvider eventCategory={TRACKING_CATEGORY}>
      <nav
        aria-label="Page navigation"
        className="lg:hidden fixed bottom-0 w-full bg-secondary border-t z-navigation"
      >
        <ul className="flex-row">
          <li
            className="font-medium tap-area w-full border-r"
            data-testid="dealer:listView"
          >
            <Track eventLabel="list-view" eventAction="toggle|click">
              <button
                className="flex w-full justify-center border-t-2 current:border-accent-blue items-center gap-8 dl-h-56 dl-current:text-accent-blue"
                aria-current={activeTab === 0}
                tabIndex={0}
                type="button"
                onClick={() => {
                  dispatch({ type: 'SET_VIEW', payload: 'list' });
                  setActiveTab(0);
                }}
              >
                <Icon type="list-24" className="mr-8" color="inherit" />
                {translate('global.general.switchToListButtonLabel') || 'List'}
              </button>
            </Track>
          </li>
          <li
            className="font-medium tap-area w-full"
            data-testid="dealer:mapView"
          >
            <Track eventLabel="map-view" eventAction="toggle|click">
              <button
                className="flex w-full justify-center border-t-2 current:border-accent-blue items-center gap-8 dl-h-56 dl-current:text-accent-blue"
                aria-current={activeTab === 1}
                tabIndex={0}
                type="button"
                onClick={() => {
                  dispatch({ type: 'SET_VIEW', payload: 'map' });
                  setActiveTab(1);
                }}
              >
                <Icon type="map-mappin-16" className="mr-8" color="inherit" />
                {translate('global.general.switchToMapButtonLabel') || 'Map'}
              </button>
            </Track>
          </li>
        </ul>
      </nav>
    </TrackingProvider>
  );
};
