import { useEffect, useMemo, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { Retailer } from 'types/retailer';

export const useClusters = (
  latestMapChangeEventValue: GoogleMapReact.ChangeEventValue | undefined,
  retailers: Retailer[] | undefined,
) => {
  const [points, setPoints] = useState<Point[]>([]);
  const { bounds, zoom } = latestMapChangeEventValue || {};
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: [
      bounds?.sw.lng,
      bounds?.sw.lat,
      bounds?.ne.lng,
      bounds?.ne.lat,
    ] as [number, number, number, number],
    zoom: zoom || 3,
    options: {
      minZoom: 0,
      maxZoom: 16,
      radius: 140,
    },
  });
  useEffect(() => {
    if (!retailers) return;
    const points = retailers?.map((retailer, i) =>
      getFeaturePoint(retailer, i),
    );
    setPoints(points);
  }, [latestMapChangeEventValue, retailers]);
  return useMemo(
    () =>
      clusters.map((cluster, i) => ({
        ...cluster,
        key:
          String(cluster.id || '') +
          cluster.geometry.coordinates?.[0] +
          cluster.geometry.coordinates?.[1] +
          i,
        children: cluster.properties.cluster
          ? supercluster?.getChildren(cluster.id as number)
          : undefined,
        expansionZoom: cluster.properties.cluster
          ? Math.min(
              supercluster?.getClusterExpansionZoom(cluster.id as number) || 30,
              20,
            )
          : 0,
      })),
    [supercluster, clusters],
  );
};

type Point = ReturnType<typeof getFeaturePoint>;
function getFeaturePoint(retailer: Retailer, index: number) {
  return {
    type: 'Feature' as const,
    properties: {
      id: index,
      cluster: false,
      retailer,
      point_count: '',
    },
    geometry: {
      type: 'Point' as const,
      coordinates: [
        parseFloat(retailer.longitude || ''),
        parseFloat(retailer.latitude || ''),
      ],
    },
  };
}
