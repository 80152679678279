import { useState } from 'react';
import { OpeningHours } from 'src/gql/graphql';

export const useCapabilitySelection = ({
  openingHours,
  phoneNumbers,
  defaultCapability,
}: {
  openingHours: {
    retailer: OpeningHours | null;
    service: OpeningHours | null;
  };
  phoneNumbers: {
    retailer: string;
    service: string;
  };
  defaultCapability?: string;
}) => {
  const [selectedCapability, setSelectedCapability] =
    useState(defaultCapability);

  const selectedOpeningHours =
    selectedCapability === 'retailer'
      ? openingHours.retailer
      : selectedCapability === 'service'
        ? openingHours.service
        : null;

  const selectedPhoneNumber =
    selectedCapability === 'retailer'
      ? phoneNumbers.retailer
      : selectedCapability === 'service'
        ? phoneNumbers.service
        : undefined;

  return {
    selectedOpeningHours,
    selectedPhoneNumber,
    setSelectedCapability,
    selectedCapability,
  };
};
