import { Icon } from '@volvo-cars/react-icons';

type DistanceAddressRowProps = {
  distance?: string;
  addressLine1: string;
  addressLine2: string;
  isMapCard?: boolean;
};

const DistanceAddressRow = ({
  distance,
  addressLine1,
  addressLine2,
  isMapCard = false,
}: DistanceAddressRowProps) => (
  <div className={`flex  ${isMapCard ? 'gap-32' : ' gap-16 justify-between'}`}>
    <p data-testid="dealer:address" className="text-secondary">
      {addressLine1} <br /> {addressLine2}
    </p>

    {distance && (
      <div className="flex-col items-center">
        <Icon type="map-mappin-16" />
        <p className="whitespace-nowrap" data-testid="dealer:distanceFromPoint">
          {distance}
        </p>
      </div>
    )}
  </div>
);

export default DistanceAddressRow;
