import React, { memo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import { Block, ExtendCSS } from 'vcc-ui';
import { CAPABILITIES } from 'src/constants/retailerCapabilities';
import { useRetailers, useStore, useTranslate } from 'src/hooks';
import { CheckboxWithLabel } from 'src/components';

const Filter = () => {
  const { setCapabilities } = useRetailers();
  const { dispatch } = useStore();
  const translate = useTranslate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    memoize((type: 'RETAILER_CAPS' | 'SERVICE_CAPS') => {
      return debounce((checked: boolean) => {
        if (checked) {
          setCapabilities &&
            setCapabilities((capabilities) => ({
              ...capabilities,
              [type]: CAPABILITIES[type],
            }));
        } else {
          setCapabilities &&
            setCapabilities((capabilities) => ({
              ...capabilities,
              [type]: [],
            }));
        }
        dispatch({ type: 'SET_SELECTED_RETAILER', payload: null });
      }, 100);
    }),
    [setCapabilities],
  );
  return (
    <Block extend={filterContainerCSS}>
      <CheckboxWithLabel
        label={translate('global.retailer.retailerCapability') || 'Dealer'}
        onChange={handleChange('RETAILER_CAPS')}
        testid="dealer:retailersFilter"
      />
      <CheckboxWithLabel
        label={translate('global.retailer.serviceCapability') || 'Service'}
        onChange={handleChange('SERVICE_CAPS')}
        testid="dealer:servicesFilter"
      />
    </Block>
  );
};
export default memo(Filter);

const filterContainerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: `0 ${3 * baselineGrid}px ${2 * baselineGrid}px ${
    3 * baselineGrid
  }px`,
  untilL: {
    paddingBottom: `${3 * baselineGrid}px`,
  },
  fromM: {
    paddingTop: baselineGrid,
  },
  '> *': {
    cursor: 'pointer',
  },
});
