import React, { memo, useCallback, useEffect, useState } from 'react';
import { Retailer } from 'types/retailer';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { getDealerId } from 'src/utils/volvoTrackingHelpers';
import { useCapabilitySelection } from 'src/hooks/useCapabilitySelection';
import { getValidSiteSlug } from 'src/utils/getValidSiteSlug';
import { isEqualRetailer } from 'src/utils/isEqualRetailer';
import { formatDistance } from 'src/utils/formatDistance';
import { useRetailerLinks } from '../../../hooks';
import {
  CapabilityTabs,
  GetDirectionsLink,
  OpeningHours,
  RetailerHeading,
  VisitRetailerLink,
} from '../..';
import { ExtendedPhoneNumber } from './PhoneNumber';
import { RetailerLinksGroup } from './RetailerLinksGroup';
import DistanceAddressRow from './DistanceAddressRow';

const ExtendedRetailerCardUI = ({
  retailer,
  wrapperRef: ref,
  selectedRetailer,
  handleRetailerClick,
  activeUserAddress,
}: {
  retailer: Retailer;
  wrapperRef: React.RefObject<HTMLDivElement>;
  selectedRetailer: Retailer | null;
  handleRetailerClick: any;
  activeUserAddress: boolean;
}) => {
  const {
    name,
    addressLine1,
    addressLine2,
    distanceFromPointKm,
    distanceFromPointMiles,
    url,
    phoneNumbers,
    capabilities,
    coordinatesMapLink,
    latitude,
    longitude,
    mapLocation,
    openingHours,
  } = retailer;
  const { locale, roadLengthUnit } = useCurrentMarketSite();
  const distance =
    roadLengthUnit === 'mile' ? distanceFromPointMiles : distanceFromPointKm;
  const isSelectedRetailer = isEqualRetailer(retailer, selectedRetailer);
  const [expanded, setExpanded] = useState(false);
  const validCapabilities = capabilities.filter(Boolean);
  const defaultCapabilityIndex = 0;
  const {
    setSelectedCapability,
    selectedOpeningHours,
    selectedPhoneNumber,
    selectedCapability,
  } = useCapabilitySelection({
    openingHours,
    phoneNumbers,
    defaultCapability: validCapabilities.at(defaultCapabilityIndex),
  });
  const selektCapability = capabilities.find((cap) => cap === 'selekt');
  const collisionCenterCapability = capabilities.find(
    (cap) => cap === 'collision_center',
  );

  const { siteSlug } = useCurrentMarketSite();
  const validSiteSlug = getValidSiteSlug(siteSlug);
  const links = useRetailerLinks(retailer, validSiteSlug);
  const isUS = validSiteSlug === 'us';
  useEffect(() => setExpanded(isSelectedRetailer), [isSelectedRetailer]);

  const openCard = () => toggleCard(true);
  const closeCard = () => toggleCard(false);

  const toggleCard = useCallback(
    (shouldExpand: boolean) => {
      if (expanded === shouldExpand) return;
      handleRetailerClick();
      selectedRetailer && setExpanded((prev) => !prev);
    },
    [handleRetailerClick, selectedRetailer, expanded],
  );

  return (
    <div
      ref={ref}
      data-testid="dealer:retailerContainer"
      className="p-24 rounded border-ring border-ring mb-24 dl-hover:border-accent-blue dl-expanded:border-ring-2 dl-current:border-ring-2"
      aria-expanded={expanded}
      aria-current={isSelectedRetailer}
      role="button"
      tabIndex={0}
      onKeyDown={openCard}
      onClick={openCard}
    >
      <div className="flex-col gap-16">
        <div className="flex-col gap-8">
          <RetailerHeading
            retailerName={name}
            isExpanded={expanded}
            onClose={closeCard}
          />
          <div className="flex-row gap-8">
            {selektCapability && (
              <div className="border-ring rounded-full py-2 px-8 w-fit border-ornament">
                <p className="micro">Selekt</p>
              </div>
            )}
            {isUS && collisionCenterCapability && (
              <div className="border-ring rounded-full py-2 px-8 w-fit border-ornament">
                <p className="micro">Certified Collision Center</p>
              </div>
            )}
          </div>
        </div>

        <CapabilityTabs
          capabilities={validCapabilities}
          defaultSelectedIndex={defaultCapabilityIndex}
          onCapabilityChanged={setSelectedCapability}
        />
        <DistanceAddressRow
          distance={
            activeUserAddress
              ? formatDistance(
                  distance || 0,
                  locale,
                  roadLengthUnit ?? 'kilometer',
                )
              : undefined
          }
          addressLine1={addressLine1}
          addressLine2={addressLine2}
        />
        {expanded && (
          <>
            <ExtendedPhoneNumber
              name={name}
              phoneNumber={selectedPhoneNumber}
            />
            <OpeningHours
              openingHours={selectedOpeningHours}
              locale={locale}
              selectedCapability={selectedCapability}
            />
          </>
        )}
      </div>

      {expanded && (
        <>
          {links.length > 0 && (
            <RetailerLinksGroup
              dealerId={getDealerId(retailer)}
              dealerInfo={name}
              links={links}
            />
          )}
          <div className="flex-col gap-8 mt-24">
            {url && <VisitRetailerLink name={name} url={url} />}
            <GetDirectionsLink
              name={name}
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              coordinatesMapLink={coordinatesMapLink}
              longitude={longitude}
              latitude={latitude}
              url={mapLocation}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default memo(ExtendedRetailerCardUI);
