type Timer = ReturnType<typeof setTimeout>;

type MapConfigType = {
  lat?: number;
  lng?: number;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  bounds?: google.maps.LatLngBounds;
};

export const MIN_ZOOM_LEVEL = 2.5;
const MAX_ZOOM_LEVEL = 20;
export const DEFAULT_DEBOUNCE_TIME_MS = 50;
export const DEFAULT_SELECTED_RETAILER_ZOOM = 17;

export const updateMapPanBoundsZoom = (
  map: google.maps.Map | null | undefined,
  mapConfig: MapConfigType,
  debounceTimeout: number = 0,
) => {
  if (!map || !mapConfig || typeof window === 'undefined') {
    return;
  }

  debounce(debounceTimeout, () => {
    if (mapConfig.lat && mapConfig.lng) {
      const { lat, lng } = mapConfig;
      map.panTo({ lat, lng });
    }

    if (mapConfig.bounds) {
      if (!mapConfig.zoom) {
        map.setZoom(MAX_ZOOM_LEVEL);
      }
      map.panToBounds(mapConfig.bounds);
      map.fitBounds(mapConfig.bounds);
    }

    if (mapConfig.zoom || mapConfig.minZoom || mapConfig.maxZoom) {
      let toSetZoom = mapConfig.zoom ?? map.getZoom() ?? MAX_ZOOM_LEVEL;
      map.setZoom(
        clamp(
          toSetZoom,
          mapConfig.minZoom ?? MIN_ZOOM_LEVEL,
          mapConfig.maxZoom ?? MAX_ZOOM_LEVEL,
        ),
      );
    }
  })();
};

const debounce = (timeout: number, fn: (...args: any[]) => any) => {
  let timer: Timer | undefined;
  return function (this: any, ...args: any[]) {
    if (timeout <= 0) {
      //No timeout we run function direct
      fn.apply(this, args);
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
};

const clamp = (num: number, min: number, max: number) =>
  Math.max(min, Math.min(num, max));

export const roundLocation = (num: number) => {
  return parseInt(num.toPrecision(3));
};
