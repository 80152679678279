import React, { memo, useEffect, useMemo } from 'react';
import chunk from 'lodash/chunk';
import { Block, ExtendCSS } from 'vcc-ui';
import { AorRetailer } from 'types/retailer';
import { useInView } from '@vcc-www/hooks';
import {
  ESTIMATED_EXTENDED_RETAILER_CARD_HEIGHT,
  ESTIMATED_RETAILER_SELECTOR_CARD_HEIGHT,
} from 'src/constants/sizes';
import {
  useDrivingDistance,
  useRetailers,
  useSelectorSettings,
  useStore,
} from 'src/hooks';
import { RetailersContextValue, StoreContextValue } from 'src/providers';
import { isEqualRetailer } from 'src/utils/isEqualRetailer';
import { getReorderedRetailers } from '../../utils/getReorderedRetailers';
import RetailerCard from '../../features/dealer-picker/retailerCard/RetailerCard';

export const RetailersList: React.MemoExoticComponent<
  () => JSX.Element | null
> = memo(() => {
  const { retailers, aorRetailers } = useRetailers();
  const { selectedRetailer, retailersListVisible } = useStore();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [retailers]);
  const chunkedRetailers = useMemo(() => chunk(retailers, 10), [retailers]);
  if (!retailers || !retailersListVisible) return null;
  return (
    <ChunkedRetailersMemo
      aorRetailers={aorRetailers || []}
      chunkedRetailers={chunkedRetailers}
      selectedRetailer={selectedRetailer}
      allRetailersLength={retailers.length}
    />
  );
});
RetailersList.displayName = 'Retailers';

type ChunkedRetailersMemoProps = {
  chunkedRetailers: RetailersContextValue['retailers'][];
  selectedRetailer: StoreContextValue['selectedRetailer'];
  allRetailersLength: number;
  aorRetailers: AorRetailer[];
};
const ChunkedRetailersMemo: React.MemoExoticComponent<
  (props: ChunkedRetailersMemoProps) => JSX.Element
> = memo(
  ({
    chunkedRetailers,
    selectedRetailer,
    allRetailersLength,
    aorRetailers,
  }: ChunkedRetailersMemoProps): JSX.Element => (
    <>
      {chunkedRetailers?.map((chunkedRetailer, key: number) => {
        const isEager =
          !!chunkedRetailer?.find((retailer) =>
            isEqualRetailer(retailer, selectedRetailer),
          ) || !key;
        return (
          <RetailersChunk
            aorRetailers={aorRetailers}
            eager={isEager}
            retailers={chunkedRetailer}
            allRetailersLength={allRetailersLength}
            key={key}
            index={key}
          />
        );
      })}
    </>
  ),
);
ChunkedRetailersMemo.displayName = 'ChunkedRetailersMemo';

type RetailersChunkProps = {
  retailers: RetailersContextValue['retailers'];
  eager?: boolean;
  index: number;
  allRetailersLength: number;
  aorRetailers: AorRetailer[];
};
const RetailersChunk = ({
  retailers,
  eager,
  index,
  allRetailersLength,
  aorRetailers,
}: RetailersChunkProps): JSX.Element | null => {
  const [inView] = useInView<HTMLDivElement>({
    rootMargin: '700px 0px 700px 0px',
  });
  const shouldRender = eager || inView;
  const { registerRetailers, isUseMyLocationAddress, getDrivingData } =
    useDrivingDistance();
  useEffect(() => {
    if (shouldRender) {
      registerRetailers(retailers);
    }
  }, [registerRetailers, retailers, shouldRender]);
  const selectorSettings = useSelectorSettings();
  const sortedRetailersByDrivingDistance = useMemo(() => {
    const retailersWithDrivingData = retailers?.reduce(
      (acc, retailer) => {
        acc.push({ drivingData: getDrivingData(retailer), retailer });
        return acc;
      },
      [] as {
        drivingData?: ReturnType<typeof getDrivingData>;
        retailer?: (typeof retailers)[number];
      }[],
    );
    const shouldSort = retailersWithDrivingData?.every(({ drivingData }) => {
      if (!drivingData) return false;
      const { distance, duration, isLoading } = drivingData;
      return distance && duration && !isLoading;
    });
    if (!shouldSort) return retailers;
    const sortedRetailersWithDrivingData = retailersWithDrivingData
      ?.sort((a, b) => {
        const distanceA = a.drivingData?.distance || 0;
        const distanceB = b.drivingData?.distance || 0;
        return distanceA - distanceB;
      })
      .map((retailerWithDrivingData) => retailerWithDrivingData.retailer);

    const prioritizedParmaPartnerCode = aorRetailers?.[0]?.parmaPartnerCode;

    if (prioritizedParmaPartnerCode && sortedRetailersWithDrivingData) {
      return getReorderedRetailers(
        sortedRetailersWithDrivingData,
        prioritizedParmaPartnerCode,
        (r) => r?.parmaPartnerCode,
      );
    }

    return sortedRetailersWithDrivingData;
  }, [retailers, aorRetailers, getDrivingData]);
  if (!sortedRetailersByDrivingDistance) return null;
  return (
    <Block
      role="list"
      extend={retailersChunkCSS(
        retailers,
        index,
        allRetailersLength,
        isUseMyLocationAddress,
        selectorSettings.useSelector,
      )}
    >
      {shouldRender &&
        sortedRetailersByDrivingDistance?.map((retailer, key) => (
          <RetailerCard
            retailer={retailer}
            key={
              key +
              (retailer?.latitude.toString() || '') +
              (retailer?.longitude.toString() || '') +
              retailer?.addressLine1
            }
          />
        ))}
    </Block>
  );
};

const retailersChunkCSS =
  (
    retailers: RetailersContextValue['retailers'],
    index: number,
    allRetailersLength: number,
    isUseMyLocationAddress: boolean,
    isSelectorChunk: boolean,
  ): ExtendCSS =>
  () => {
    const cardHeight = isSelectorChunk
      ? isUseMyLocationAddress
        ? ESTIMATED_RETAILER_SELECTOR_CARD_HEIGHT + 20
        : ESTIMATED_RETAILER_SELECTOR_CARD_HEIGHT
      : isUseMyLocationAddress
        ? ESTIMATED_EXTENDED_RETAILER_CARD_HEIGHT + 20
        : ESTIMATED_EXTENDED_RETAILER_CARD_HEIGHT;
    return {
      ...(retailers &&
        index !== allRetailersLength - 1 && {
          minHeight: cardHeight * retailers.length,
          justifyContent: 'space-around',
        }),
      display: 'flex',
      flexDirection: 'column',
    };
  };
