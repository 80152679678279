import React, { ComponentType, KeyboardEvent, memo } from 'react';
import { TextInput } from '@volvo-cars/react-forms';
import { useTracker } from '@volvo-cars/tracking';
import { IconButton } from '@volvo-cars/react-icons';
import { useRetailers, useTranslate } from 'src/hooks';

const TextInputWithIcon: ComponentType<React.PropsWithChildren<any>> = ({
  iconType,
  inputRef,
  handleSubmit,
  onFocus,
  ...props
}) => {
  const { isLoading } = useRetailers();
  const track = useTracker();
  const translate = useTranslate();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      track.search(props.value);
      return handleSubmit();
    }
  };

  return (
    <div className="w-full px-24 py-16">
      <TextInput
        contentAfter={
          isLoading ? (
            <progress aria-label="Loading" className="spinner w-16" />
          ) : (
            <IconButton
              onClick={handleSubmit}
              iconName={iconType}
              aria-label={
                translate('search.form.searchButtonAriaLabel') ||
                'Search and continue to the filtered results'
              }
              variant="clear"
              data-testid="dealer:searchButton"
            />
          )
        }
        id="address-input"
        htmlFor="address-input"
        ref={inputRef}
        type="search"
        role="search"
        {...props}
        onKeyDown={handleKeyDown}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          track.search(props.value);
          onFocus(e);
        }}
        data-testid="dealer:search"
      />
    </div>
  );
};
export default memo(TextInputWithIcon);
