import React, { useMemo } from 'react';
import { useSelectorSettings, useStore, useTranslate } from 'src/hooks';
import { useUserLocation } from '../../../hooks';

type HelpTextProps = { setIsLoading?: (isLoading: boolean) => void };

export const HelpText = ({ setIsLoading }: HelpTextProps): JSX.Element => {
  const selectorSettings = useSelectorSettings();
  const { view } = useStore();
  const translate = useTranslate();
  const translateParams = useMemo(
    () => ({
      a: function aTag(chunks: string) {
        return (
          <UseYourLocationWrapper setIsLoading={setIsLoading} chunks={chunks} />
        );
      },
    }),
    [setIsLoading],
  );
  return (
    <div
      className={`${view === 'map' && 'until-lg:hidden'} mt-16 md:mt-24 bg-secondary py-16 px-24 md:py-24`}
      data-testid="dealer:helpTextContainer"
    >
      <p className="text-secondary">
        {selectorSettings.useSelector
          ? translate('selector.general.helpText') ||
            'Search by zip code and select a retailer near you to place your reservation'
          : translate('global.general.helpText', translateParams) ||
            'Search by address, city, state, zip code or dealer name, or use your location to view dealers near you.'}
      </p>
    </div>
  );
};

type UseYourLocationWrapperProps = {
  chunks: string;
  setIsLoading?: (isLoading: boolean) => void;
};
const UseYourLocationWrapper = ({
  chunks,
  setIsLoading,
}: UseYourLocationWrapperProps): JSX.Element => {
  const translate = useTranslate();
  const { handleClick } = useUserLocation({ setIsLoading });
  return (
    <button
      className="text-secondary link-inline"
      data-testid="dealer:useYourLocation"
      aria-label={
        translate('global.general.helpTextLinkAriaLabel') ||
        'Click to search near your location'
      }
      onClick={handleClick}
      type="button"
      tabIndex={-1}
    >
      {chunks}
    </button>
  );
};
