import React from 'react';
import { useTranslate, useUserLocation } from 'src/hooks';

type UserMyLocationProps = {
  setIsLoading?: (isLoading: boolean) => void;
};

export const UseMyLocation = ({
  setIsLoading,
}: UserMyLocationProps): JSX.Element => {
  const translate = useTranslate();
  const { handleClick } = useUserLocation({ setIsLoading });
  return (
    <button
      type="button"
      data-testid="useMyLocation"
      className="button-text my-8"
      onClick={handleClick}
      onMouseDown={(event: any) => event.preventDefault()}
    >
      {translate('search.form.useMyLocationButtonLabel')}
    </button>
  );
};
