import { IconButton } from '@volvo-cars/react-icons';
import { useTranslate } from 'src/providers';

type RetailerHeadingProps = {
  retailerName: string;
  isExpanded: boolean;
  onClose: () => void;
};

export const RetailerHeading = ({
  retailerName: name,
  isExpanded,
  onClose,
}: RetailerHeadingProps) => {
  const translate = useTranslate();
  return (
    <div className="flex justify-between items-center gap-16">
      <p
        className="font-medium"
        data-testid="dealer:name"
        aria-label={
          translate('global.retailer.retailerNameAriaLabel', {
            retailerName: name,
          }) || `${name} Click to view on map`
        }
      >
        {name}
      </p>
      <IconButton
        onClick={() => isExpanded && onClose()}
        aria-label="toggle-card"
        type="button"
        bleed
        variant="clear"
        iconName={
          isExpanded ? 'navigation-chevronup' : 'navigation-chevrondown'
        }
      />
    </div>
  );
};
