import { useEffect } from 'react';
import { TabGroupState, useTab, useTabList } from '@volvo-cars/react-headless';
import { DictionaryItemTypes } from 'src/content-management/DictionaryItemTypes';
import { useTranslate } from 'src/providers';

type CapabilityTabProps = {
  index: number;
  capability: string;
  tabGroupState: TabGroupState;
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

const CapabilityTab = ({
  index,
  capability,
  tabGroupState,
  onClick,
}: CapabilityTabProps) => {
  const translate = useTranslate();
  const { tabProps } = useTab({
    state: tabGroupState,
    index,
  });
  return (
    <button
      {...tabProps}
      key={tabProps.key}
      className="px-8 font-medium tap-area rounded-sm"
      type="button"
      onClick={onClick}
    >
      <span className="flex items-center h-48 border-b-2 border-transparent tab-selected:border-primary transition-colors">
        {translateCapability(capability, translate)}
      </span>
    </button>
  );
};

type CapabilityTabsProps = {
  capabilities: string[];
  defaultSelectedIndex?: number;
  onCapabilityChanged: (c: string) => void;
};

const translateCapability = (
  capability: string,
  translate: (itemKey: keyof DictionaryItemTypes) => string,
) => {
  switch (capability) {
    case 'service':
      return translate('global.retailer.serviceCapability') || 'Service';
    case 'retailer':
      return translate('global.retailer.retailerCapability') || 'Dealer';
    default:
      return '';
  }
};

export const CapabilityTabs = ({
  capabilities,
  defaultSelectedIndex = 0,
  onCapabilityChanged,
}: CapabilityTabsProps) => {
  const { tabListProps, tabGroupState, selectedIndex } = useTabList({
    defaultSelectedIndex,
  });

  useEffect(() => {
    const capability = capabilities[selectedIndex];
    onCapabilityChanged(capability);
  }, [capabilities, onCapabilityChanged, selectedIndex]);

  return (
    <div {...tabListProps} className="flex gap-8 -mx-8">
      {capabilities.map((capability, index) => (
        <CapabilityTab
          onClick={tabListProps.onClick}
          key={capability}
          index={index}
          capability={capability}
          tabGroupState={tabGroupState}
        />
      ))}
    </div>
  );
};
