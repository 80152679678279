import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact, {
  Coords,
  ChangeEventValue as MapChangeEventValue,
  MapOptions,
} from 'google-map-react';
import { createRoot, Root } from 'react-dom/client';
import { Block, ExtendCSS } from 'vcc-ui';
import { Retailer } from 'types/retailer';
import { useTopbarHeight } from '@vcc-www/hooks';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import type { SiteSlug } from '@volvo-cars/market-sites';
import { Cluster, MapControls, Marker } from 'src/features';
import {
  BOTTOM_BAR_HEIGHT,
  MAP_RETAILER_CARD_HEIGHT,
  SUBMENU_HEIGHT,
  SUBMENU_NO_TOGGLES_HEIGHT,
} from 'src/constants/sizes';
import { GOOGLE_CLIENT_ID } from 'src/constants/gql';
import { useRetailers, useSelectorSettings, useStore } from 'src/hooks';
import {
  DEFAULT_SELECTED_RETAILER_ZOOM,
  MIN_ZOOM_LEVEL,
  roundLocation,
  updateMapPanBoundsZoom,
} from 'src/utils/mapUtils';
import { DistanceUnit } from 'src/utils/formatDistance';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useClusters } from './useClusters';

// used to limit the zoom level on the map on initial page load by excluding retailers outside of a boundary
const initialRetailerLimits: { [key in SiteSlug]?: { minLatitude: number } } = {
  fr: {
    minLatitude: 30,
  },
};
function getInitialRetailers(siteSlug: SiteSlug, retailers: Retailer[]) {
  const currentMarketRetailerLimit = initialRetailerLimits[siteSlug];
  if (!retailers || !currentMarketRetailerLimit) return retailers;
  return retailers.filter(
    (retailer) =>
      retailer?.latitude &&
      Number(retailer.latitude) > currentMarketRetailerLimit.minLatitude,
  );
}
export const Map = (): JSX.Element => {
  const { marketName, locale, regionCode, siteSlug, roadLengthUnit } =
    useCurrentMarketSite();
  const [markersVisibility, setMarkersVisibility] = useState(true);
  const [userLocation, setUserLocation] = useState<Coords | null>(null);
  const topbarHeight = useTopbarHeight();
  const [latestMapChangeEventValue, setLatestMapChangeEventValue] =
    useState<MapChangeEventValue>();
  const { dispatch, view, selectedRetailer, map, retailersListVisible } =
    useStore();
  const { retailers } = useRetailers();
  const selectorSettings = useSelectorSettings();
  const { mapReboundMaxRadius: maxRadius, enableDatelineCrossing } =
    useFeatureFlags();
  const clusters = useClusters(latestMapChangeEventValue, retailers);
  const shouldBeVisible = view === 'map';
  const isIntlMarket = marketName === 'International';

  useEffect(() => {
    if (isIntlMarket) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { longitude, latitude } = position.coords;
        setUserLocation({ lng: longitude, lat: latitude });
      });
    }
  }, [isIntlMarket]);

  useEffect(() => {
    if (!retailers || !retailers.length || !map) return;
    setMarkersVisibility(false);
    const bounds = new google.maps.LatLngBounds();
    const hasMaxRadius = !!maxRadius && maxRadius !== -1;

    const shownRetailers = retailersListVisible
      ? hasMaxRadius
        ? getMaxRadiusFilteredRetailers(
            retailers.slice(0, 3),
            maxRadius,
            roadLengthUnit,
          )
        : retailers.slice(0, 3)
      : getInitialRetailers(siteSlug as SiteSlug, retailers);

    if (!selectedRetailer && shownRetailers && !userLocation) {
      shownRetailers.forEach((retailer: any) => {
        bounds.extend({
          lat: parseFloat(retailer.latitude),
          lng: parseFloat(retailer.longitude),
        });
      });

      // Add some "zoom padding" if only one retailer was found, else let the bounds to the job
      const zoom =
        shownRetailers.length === 1
          ? DEFAULT_SELECTED_RETAILER_ZOOM
          : undefined;
      const center = {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      };

      updateMapPanBoundsZoom(map, { ...center, bounds, zoom }, 50);
    }

    if (userLocation && !selectedRetailer) {
      updateMapPanBoundsZoom(
        map,
        {
          lng: roundLocation(userLocation.lng),
          lat: roundLocation(userLocation.lat),
          zoom: MIN_ZOOM_LEVEL,
        },
        50,
      );
    }
    const markersVisibilityCallback = () => {
      setMarkersVisibility(true);
    };
    const idleListener = google.maps.event.addListener(
      map,
      'idle',
      markersVisibilityCallback,
    );
    return () => {
      google.maps.event.removeListener(idleListener);
      setMarkersVisibility(false);
    };
  }, [
    map,
    maxRadius,
    retailers,
    retailersListVisible,
    selectedRetailer,
    siteSlug,
    roadLengthUnit,
    isIntlMarket,
    userLocation,
  ]);

  const mapControlsRoot = useRef<Root | null>(null);

  const handleApiLoaded = (
    map: google.maps.Map | null,
    maps: typeof google.maps,
  ) => {
    dispatch({ type: 'SET_MAP', payload: map });
    const controlButtonDiv = document.createElement('div');
    if (!map) return;
    const root = mapControlsRoot.current || createRoot(controlButtonDiv);
    mapControlsRoot.current = root;
    root.render(<MapControls map={map} />);
    map.controls[maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);
    if (retailers?.length) return;
    const geocoder = new maps.Geocoder();
    !isIntlMarket &&
      geocoder.geocode({ address: marketName }, (results, status) => {
        if (status === maps.GeocoderStatus.OK && results) {
          const { lat, lng } = results[0].geometry.location;
          const bounds = results[0].geometry.viewport;

          updateMapPanBoundsZoom(map, {
            lat: lat(),
            lng: lng(),
            bounds,
            zoom: 5,
          });

          const location = results[0].geometry.location.toJSON();
          dispatch({
            type: 'SET_SITE_LOCATION',
            payload: [location.lat, location.lng],
          });
        }
      });
  };
  const untilLHeight =
    topbarHeight +
    BOTTOM_BAR_HEIGHT +
    (selectorSettings.useSelector
      ? SUBMENU_NO_TOGGLES_HEIGHT
      : SUBMENU_HEIGHT) +
    (selectedRetailer ? MAP_RETAILER_CARD_HEIGHT : 0);
  return (
    <Block
      extend={containerCSS(untilLHeight, shouldBeVisible)}
      data-testid="dealer:mapViewContainer"
    >
      <GoogleMapReact
        bootstrapURLKeys={
          {
            client: GOOGLE_CLIENT_ID,
            v: '3.44',
            language: locale,
            region: regionCode,
          } as any
        }
        defaultCenter={{ lat: 0, lng: 0 }}
        options={createGoogleMapOptions({
          isIntl: siteSlug === 'intl',
          useRestrictions: !enableDatelineCrossing,
        })}
        defaultZoom={5}
        onChange={setLatestMapChangeEventValue}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {clusters.map((cluster) => {
          const {
            key,
            geometry: { coordinates },
          } = cluster;
          const lng = coordinates?.[0];
          const lat = coordinates?.[1];
          if (!cluster.properties.cluster) {
            return (
              <Marker
                visible={markersVisibility}
                key={key}
                lng={lng}
                lat={lat}
                map={map}
                retailer={cluster.properties.retailer}
              />
            );
          }
          return (
            <Cluster
              key={key}
              lng={lng}
              lat={lat}
              map={map}
              cluster={cluster}
            />
          );
        })}
      </GoogleMapReact>
    </Block>
  );
};
const containerCSS =
  (untilLHeight: number, shouldBeVisible: boolean): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    height: `calc(100vh - ${untilLHeight}px)`,
    width: '100%',
    visibility: 'hidden',
    left: 10000,
    position: 'fixed',
    ...(shouldBeVisible && {
      visibility: 'visible',
      left: 0,
      boxSizing: 'border-box',
    }),
    fromL: {
      visibility: 'visible',
      padding: `${3 * baselineGrid}px 0`,
      height: 'inherit',
      width: 'inherit',
      left: 0,
      boxSizing: 'border-box',
      position: 'relative',
    },
  });

/** Returns array containing only retailers within the given maxRadius */
const getMaxRadiusFilteredRetailers = (
  retailers: Retailer[],
  maxRadius: number,
  distanceUnit: DistanceUnit,
): Retailer[] => {
  return retailers.filter((retailer, index) => {
    const distance =
      distanceUnit === 'mile'
        ? retailer.distanceFromPointMiles ?? 0
        : retailer.distanceFromPointKm ?? 0;
    return index === 0 || distance < maxRadius;
  });
};

const createGoogleMapOptions = ({
  isIntl,
  useRestrictions,
}: {
  isIntl: boolean;
  useRestrictions: boolean;
}): MapOptions => {
  const mapOptions: MapOptions = {
    gestureHandling: 'greedy',
    disableDefaultUI: true,
  };

  // https://github.com/google-map-react/google-map-react/blob/master/API.md#override-the-default-minimum-zoom
  // Override default minimum zoom only for intl. As mentioned above,
  // minZoom is buggy and ruins the marker calculation when mixed with restriction
  if (isIntl) {
    mapOptions.minZoom = MIN_ZOOM_LEVEL;
  } else if (useRestrictions) {
    // @ts-ignore restriction isn't defined in @types/google-map-react
    mapOptions.restriction = { latLngBounds: RESTRICTION_BOUNDS };
  }

  return mapOptions;
};

// Restrictive bounds that prevent users from dragging map more than once across the world
const RESTRICTION_BOUNDS = { north: 85, south: -85, west: -179.9, east: 179.9 };
