// Covers title, tags + address + padding 16px
export const DEFAULT_CARD_HEIGHT = 140;
export const BOTTOM_BAR_HEIGHT = 56;
export const SUBMENU_HEIGHT = 128;
export const SUBMENU_NO_TOGGLES_HEIGHT = 88;
export const SUBMENU_HEIGHT_FROML = 144;
export const MAP_RETAILER_CARD_HEIGHT = 80;
export const MAX_CONTENT_WIDTH = 1016;
export const ESTIMATED_EXTENDED_RETAILER_CARD_HEIGHT = 176;
export const ESTIMATED_RETAILER_SELECTOR_CARD_HEIGHT = 160;
