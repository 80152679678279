import React from 'react';
import Link from 'next/link';
import { Track } from '@volvo-cars/tracking';
import { TRACKING_CATEGORY } from 'src/constants/tracking';
import { useTranslate } from 'src/hooks';

type GetDirectionsLinkProps = {
  coordinatesMapLink: boolean;
  name: string;
  addressLine1: string;
  addressLine2: string;
  longitude: string | null;
  latitude: string | null;
  url: string | null;
};
export const GetDirectionsLink = ({
  name,
  addressLine1,
  addressLine2,
  coordinatesMapLink,
  longitude,
  latitude,
  url,
}: GetDirectionsLinkProps): JSX.Element => {
  const translate = useTranslate();
  const mapLinkQuery = getMapLinkQuery({
    coordinatesMapLink,
    name,
    addressLine1,
    addressLine2,
    longitude,
    latitude,
  });
  const href = url || `https://maps.google.com/?q=${mapLinkQuery}`;

  return (
    <Track
      eventLabel="driving instructions"
      eventAction="link|click"
      event="custom_event"
      customData={{ eventCategory: TRACKING_CATEGORY }}
    >
      <Link
        className="button-outlined"
        data-testid="dealer:getDirection"
        target="_blank"
        href={href}
        aria-label={
          translate('global.retailer.getDirectionsAriaLabel', {
            retailerName: name,
          }) || `Get directions ${name}`
        }
      >
        {translate('global.retailer.getDirections') || 'Get directions'}
      </Link>
    </Track>
  );
};
export function getMapLinkQuery({
  coordinatesMapLink,
  name,
  addressLine1,
  addressLine2,
  longitude = '',
  latitude = '',
}: Omit<GetDirectionsLinkProps, 'url'>) {
  const query = coordinatesMapLink
    ? `${latitude},${longitude}`
    : `${name}, ${addressLine1 || ''} ${addressLine2 || ''}`;
  const validSingleQueryParam = query.replaceAll('&', '%26');
  return validSingleQueryParam;
}
