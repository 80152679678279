import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import { Icon } from '@volvo-cars/react-icons';
import { Track, TrackingProvider } from '@volvo-cars/tracking';
import { useTranslate } from 'src/providers';
import { TRACKING_CATEGORY } from '../../../constants/tracking';

type PhoneNumberProps = {
  name: string;
  phoneNumber?: string;
  extend?: ExtendCSS;
};

export const ExtendedPhoneNumber = ({
  name,
  phoneNumber,
}: PhoneNumberProps) => {
  const translate = useTranslate();

  return phoneNumber ? (
    <TrackingProvider eventCategory={TRACKING_CATEGORY}>
      <div
        className="flex items-center gap-8 w-fit"
        role="presentation"
        onClick={(e) => e.stopPropagation()} // To not toggle the card on click
      >
        <Icon type="communication-phone-16" />
        <Track eventLabel="phone number" eventAction="link|click">
          <a
            href={`tel:${phoneNumber}`}
            aria-label={
              translate('global.retailer.phoneNumberAriaLabel', {
                retailerName: name,
                phoneNumber,
              }) || `${name} Phone: ${phoneNumber}`
            }
            className="text-accent-blue font-16"
            data-testid="dealer:phoneNumber"
          >
            {phoneNumber}
          </a>
        </Track>
      </div>
    </TrackingProvider>
  ) : null;
};
